import React from 'react'

import { Flex } from 'components/layouts'

import styles from './styles.module.scss'


export const MainLayout = ({ children, }) =>
  <Flex className={styles.container} justifyBetween f1 column>
    {children}
  </Flex>
