import React from 'react'
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom'

import {
  Start,
  Projects,
  Contacts,
} from 'pages'

import {
  MainLayout,
  Header,
  Footer,
  Nav,
} from 'components/layouts'


const Routes = () =>
  <Router>
    <MainLayout>
      <Header />
      <Switch>
        <Route exact path="/" component={Start} />
        <Route exact path="/works" component={Projects} />
        <Route exact path="/hello" component={Contacts} />
        {/*<Route path="/404" component={Page404} />*/}
        {/*<Route path="*" component={NoMatch} />*/}
        <Redirect to="/" />
      </Switch>
      <Footer />
    </MainLayout>
  </Router>

export default Routes
