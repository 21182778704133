import React from 'react'

import { Flex } from 'components/layouts'
import { Cell } from 'components/UI'

import { sites } from 'assets/sites'

import styles from './styles.module.scss'


const projects = [
  {
    name: 'Xsolla доп.админ',
    years: '2021',
    picture: sites.xsolla,
    link: 'NDA',
    description: 'Красивый микрофронтенд, с обработкой данных, и сборкой в таблицы и графики. Разработал фреймворк, по которому админка собираться будет сама. Немного потимлидил (команда из 5 человек), немного помог в дизайне.',
  },
  {
    name: 'Rocketech',
    years: '2020',
    picture: sites.rocket,
    link: 'https://rocketech.it',
    description: 'Разработал сайт имея на руках только дизайн. Помимо всей вёрстки (декстоп и мобайл), оптимизации (особенно анимации), собрал удобную архитектуру, для приятной поддержки.',
  },
  {
    name: 'НацПроект Экология',
    years: '2020',
    picture: sites.egais,
    link: 'https://нацпроектэкология.рф/map/',
    description: 'Полу-гос проект по мониторингу субъектов РФ. 80% работы — с картами, MBTiles, GeoJSON, PostGIS, WebGL, etc, и не только на фронте, но и на бэке.',
  },
  {
    name: 'Кредитная платформа E-CREDIT',
    years: '2019',
    picture: sites.ecred,
    link: 'https://ecredit.one',
    description: '"Под ключ" 2 проекта: онлайн-анкеты ОСАГО/КАСКО (поля с локальной/серверной валидацией, маски, кастомные рэнжеры, визарды, итп), и также админка со статами (таблицами), которых было штук 50.',
  },
  {
    name: 'Портфолио Творческой студии Драгунского',
    years: '2015',
    picture: sites.dragunsky,
    link: 'https://dragunsky.pro',
    description: 'Сделал лэндинг моему старому другу (он реально внук того самого Драгунского). За 5 лет оба выросли профессионально, так что скоро — новый сайт.',
  },
  {
    name: 'Сайт-визитка BACKSTAGE Moscow',
    years: '2018',
    picture: sites.backstage,
    link: 'https://backstage.moscow',
    description: 'Разработка под ключ, моё вообще всё — включая концепцию, дизайн, компоновку и даже хостинг (собственно всё, кроме текстов и медиа). Особенно горжусь формой обратной связи в «Контактах» :3',
  },
  {
    name: 'Юридическое агентство Априори',
    years: '2018',
    picture: sites.apriori,
    link: 'https://aprioridv.ru',
    description: 'В оригинальном дизайне были ещё вертикальные линии, буквально была сетка, но без неё идея с ассиметрией в блоках выглядит... странно. Зато здесь очень милая мобильная версия.',
  },
  {
    name: 'Talk About Me',
    years: '2018',
    picture: sites.tam,
    link: 'закрыт/проект на гите',
    description: 'MVP проекта, который видимо так и не получил инвестиций. Сделан и фронт, и бэк, и даже сокеты для IM и оповещений.',
  },
  {
    name: 'Криптобиржа GoldJob',
    years: '2018',
    picture: sites.goldjob,
    link: 'https://app.goldjob.com/',
    description: 'Выполнил фронтэнд проекта, организовал архитектуру, немного потимлидил, потом покинул проект из-за идейных разногласий.',
  },
  {
    name: 'Mac Arena',
    years: '2019',
    picture: sites.mac,
    link: 'http://mac.digital-lab.ru/',
    description: 'В проекте реализованы логин, регистрация, графики (несколько видов), одиночные запросы, множественные, синхрон/асинхрон, запросы кнопкой/фокусом/троттлингом — в общем, здесь есть ВСЁ, как в Греции.',
  },
]


export const Projects = () => {
  const age = () => new Date(Date.now() - new Date(1990, 8, 26).getTime()).getUTCFullYear() - 1970

  return (
    <Flex className={styles.projects} alignCenter column f1>
      <p>Здесь несколько проектов, которые мне нравятся, которые решают разные проблемы, и боли-мене актуальны.</p>
      {
        projects.map( (project, key) =>
          <Cell {...project} key={key} />
        )
      }
    </Flex>
  )
}
