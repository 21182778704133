import React, { Component } from 'react'

import { Flex } from 'components/layouts'
import { Icon } from 'components/UI'

import styles from './styles.module.scss'

const date = new Date()

export const Footer = () =>
  <Flex center className={styles.container}>
    <h1>{date.getFullYear()} © nikonmockey.ru</h1>
  </Flex>
