import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'

import { Flex } from 'components/layouts'
import { Logo } from 'components/UI'

import styles from './styles.module.scss'


export const Header = () =>
  <Flex alignCenter justifyBetween className={styles.container}>
    <NavLink exact to="/"><Logo /></NavLink>
    <NavLink exact to="/" activeClassName={styles.active} className={styles.link}>Обо мне</NavLink>
    <NavLink to="/works" activeClassName={styles.active} className={styles.link}>Проекты</NavLink>
    <NavLink to="/hello" activeClassName={styles.active} className={styles.link}>Контакты</NavLink>
  </Flex>
