import React from 'react'

import { Flex } from 'components/layouts'
import { Social } from 'components/UI'

import port from 'assets/resume.pdf'
import styles from './styles.module.scss'


export const Contacts = () =>
    <Flex className={styles.contacts} column center>
      <Social social="mail" link="mailto:hi@yeap.me" />
      <Social social="git" link="https://github.com/nikonmockey" />
      <Social social="tg" link="https://teleg.run/nikonmockey" />
      {/*<Social social="ig" link="https://instagram.com/nikonmockey" />*/}
      <p className={styles.apost}>FYI: c 02/2020 я решил покинуть соцсети, хотя бы на время, так как они стали отнимать слишком много времени, а всё необходимую инфу я получаю из телеги <br />🤷</p>
      <Social social="po" link={port} style={{ marginTop: 24 }}/>
    </Flex>
