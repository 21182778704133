import React from 'react'

import { Flex } from 'components/layouts'
import { Icon } from 'components/UI'

import paraparapam from 'assets/paraparapam.jpg'
import styles from './styles.module.scss'


export const Start = () => {
  const age = () => new Date(Date.now() - new Date(1990, 8, 26).getTime()).getUTCFullYear() - 1970

  return (
    <Flex className={styles.start} column center>
      <h1>привет</h1>
      <Flex center column mb24>
        <p>меня зовут Никон.<br/>я разработчик из Москвы.</p>
        <p>специализируюсь на фронт-энде, но имею опыт во всём,<br/>что входит в разработку интернет-продукта,<br/>включая дизайн/ux/позиционирование</p>
      </Flex>
      <Flex center column mb24>
        <p>{age()} года, неполное высшее, 8 лет коммерческой разработки,<br/>а начинал со школьного сайта, в далеком 2005.</p>
        <p>в 2010 прошёл летний курс в КБ "Стрелка"<br/>по графическому дизайну.</p>
        <p>после 2015 перешёл на JavaScript фуллстек,<br/>и плавно ушёл во фронтенд, спасибо react'у.</p>
        <p>были и свои стартапы и проекты,<br/>на которых заработал много... опыта ;)</p>
      </Flex>
      <Flex center column mb24>
        <p>стек который нравится и люблю:<br/>React/Redux/Node/Koa2/Mongo.</p>
        <p>есть опыт во многих инструментах/языках, <br />от RubyOnRails до GraphQL (если говорить о вебе).</p>
        <p>есть два проекта на React Native.<br/>10 лет назад писал на C-подобных</p>
      </Flex>
      <Flex center column mb24 className={styles.lining}>
        <p>из креативной семьи, что сильно отразилось на моём подходе к работе. писал раньше музыку, песни были на радио, сейчас больше как хобби. работаю только удалённо, потому что это значительно экономит время и нервы. 90% времени на связи, проблем это никогда не вызывало, а со временем превращалось только в плюсы. бояться этого не нужно, нужно бояться плохих исполнителей.</p>
      </Flex>
      <Flex center column mb24 className={styles.doesntmatter}>
        <p>здесь абзац про «стрессоустойчивость» и т.п., но кому это интересно?</p>
      </Flex>
      <div className={styles.icon} />
    </Flex>
  )
}
