import dragunsky from './dragunsky.jpg'
import apriori from './apriori.jpg'
import backstage from './backstage.jpg'
import goldjob from './goldjob.jpg'
import tam from './tam.jpg'
import mac from './mac.jpg'
import ecred from './ecred.jpg'
import egais from './egais.jpg'
import rocket from './rocket.jpg'
import xsolla from './xsolla.jpg'

export const sites = {
  dragunsky,
  apriori,
  backstage,
  goldjob,
  tam,
  mac,
  ecred,
  egais,
  rocket,
  xsolla,
}
