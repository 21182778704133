import React from 'react'
import { Flex } from 'components/layouts'
import { Icon } from 'components/UI'

import { icons } from 'assets'
import styles from './styles.module.scss'


export const Social = ({ social, link, style }) => (
  <Flex className={styles.container} style={style}>
    <a href={link} target="_blank">
      <Icon src={icons[social]} />
      <span>
        {
          social === "po"
            ? "резюме"
            : social === "mail"
              ? "hi@yeap.me"
              : "@nikonmockey"
        }
      </span>
    </a>
  </Flex>
)
