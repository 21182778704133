import React from 'react'
import { Flex } from 'components/layouts'
import { Icon } from 'components/UI'

import styles from './styles.module.scss'


export const Cell = ({ name, description, picture, link }) =>
  link.startsWith('http')
    ? (
      <a href={link} target="_blank" rel="noopener noreferrer" className={styles.container}>
        <Flex justifyBetween className={styles.wrapper}>
          <Icon src={picture} className={styles.pic} />
          <Flex column className={styles.right}>
            <h3>{name}</h3>
            <a href={link} target="_blank" rel="noopener noreferrer" className={styles.link}>{link}</a>
            <p>{description}</p>
          </Flex>
        </Flex>
      </a>
    )
    : (
      <div className={styles.container}>
        <Flex justifyBetween className={styles.wrapper}>
          <Icon src={picture} className={styles.pic} />
          <Flex column className={styles.right}>
            <h3>{name}</h3>
            <p className={styles.link}>{link}</p>
            <p>{description}</p>
          </Flex>
        </Flex>
      </div>
    )
