import mail from './mail.svg'
import fb from './fb.svg'
import tg from './tg.svg'
import ig from './ig.svg'
import po from './po.svg'
import git from './git.svg'

export const icons = {
  mail,
  fb,
  tg,
  ig,
  po,
  git,
}
